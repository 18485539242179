import logo from './logo.svg';
import './App.css';
import {Component} from "react";
import DeviceHandler from "./api/deviceHandler";
import {Alert, Button, Col, DropdownButton, FormControl, InputGroup, ProgressBar, Row, Spinner, Form, Dropdown} from "react-bootstrap";
import React from "react";
import QRCode from 'qrcode.react';
import {QrReader} from "react-qr-reader";

let baseUrl;
//const baseUrl = "http://localhost:7071/api";

//const TRANSACTION_TIMEOUT = 60;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            width: document.body.clientWidth,
            height: document.body.clientHeight
        };

        this.continueWithLoading();
    }

    continueWithLoading() {
        const request = new XMLHttpRequest();
        request.open('GET', '/backend.txt', false);  // `false` makes the request synchronous
        request.send(null);

        if (request.status === 200) {
            console.log(request.responseText);
        }

        window.restApiBaseUrl = request.responseText;
        console.log("base URL",window.restApiBaseUrl);
        baseUrl = window.restApiBaseUrl;
        //baseUrl = "http://localhost:7071/api";
    }

    async init() {

        const obj = {
            loading: false
        };
        for(let key in this.state) {
            obj[key] = null;
        }
        obj.width = document.body.clientWidth;
        obj.height = document.body.clientHeight;
        this.setState(obj);

        this.device = new DeviceHandler(this.regId, this.deviceKey, baseUrl, this.queryDict.l, this.queryDict.r);

        const authResult = await this.device.init();

        if(authResult.success===true) {
            //All good
            if(authResult.regId!=null) {
                this.regId = authResult.regId;
                window.localStorage.setItem("regId", this.regId);
            }
            this.setState({
                loading: false,
                showPos: true,
                step: 0,
                baseCurrency: this.device.baseCurrency,
                supportedCurrencies: this.device.supportedCurrencies
            });
            return;
        }

        if(authResult.error.code===30001) {
            this.setState({
                loading: false,
                showNotRegistered: true
            });
            return;
        }

        this.setState({
            loading: false,
            showError: authResult.error.msg
        });

        /*
        console.log(await device.init());

        let timeout;
        const tx = await device.startTransaction("EUR", 0.10, "BTC-LN", (success) => {
            clearTimeout(timeout);
            console.log("TX success: "+success);
        });
        console.log(tx);
        timeout = setTimeout(() => {
            tx.cancel();
            console.log("Timed out, cancel");
        }, 60000);*/

    }

    componentDidMount() {

        this.queryDict = {};
        window.location.search.substr(1).split("&").forEach(function(item) {
            this.queryDict[item.split("=")[0]] = item.split("=")[1]
        }.bind(this));

        window.history.replaceState({},"","/");

        if(window.localStorage.getItem("regId")==null || window.localStorage.getItem("deviceKey")==null) {
            this.regId = DeviceHandler.generateRegId();
            this.deviceKey = DeviceHandler.generateKeyPair().privkeyHex;
            window.localStorage.setItem("regId", this.regId);
            window.localStorage.setItem("deviceKey", this.deviceKey);
        } else {
            this.regId = window.localStorage.getItem("regId");
            this.deviceKey = window.localStorage.getItem("deviceKey");
        }

        window.addEventListener("resize", () => {
            this.setState({
                width: document.body.clientWidth,
                height: document.body.clientHeight
            });
        });

        this.init();

    }

    async clickNumber(num) {
        const pos = this.state.payPos==null ? -2 : this.state.payPos;
        if(this.state.payPos==null) {
            await this.setState({
                payVal: ""+num,
                payPos: pos+1
            });
        } else {
            await this.setState({
                payVal: (this.state.payVal==null ? ""+num : this.state.payVal+""+num),
                payPos: pos+1
            });
        }
    }

    async startPayment() {
        this.setState({
            initiatingPayment: true,
            showPos: false,
            startPaymentErr: false,
            step: 0
        });

        if(this.state.payVal==null || this.state.payVal==0) return;

        if(parseInt(this.state.payVal)<parseInt(this.state.baseVal)) return;

        console.log("Ref: ", this.transactionRef);

        const tip = (parseInt(this.state.payVal)-parseInt(this.state.baseVal))/100;

        const tx = await this.device.startTransaction(this.state.baseCurrency, parseInt(this.state.payVal)/100, "BTC-LN", (success) => {
            //clearTimeout(timeout);
            this.setState({
                showQR: false,
                paymentSuccess: success
            });
            console.log("TX success: "+success);
        }, this.state.txRef==="" ? null : this.state.txRef, tip>0 ? tip : null);

        if(tx.qr==null) {
            this.setState({
                startPaymentErr: tx.msg,
                initiatingPayment: false,
                showPos: true,
                step: 0
            });
            return;
        }

        const pr = await tx.toLegacy();

        this.tx = tx;

        this.setState({
            initiatingPayment: false,
            showQR: pr,
            txValue: parseInt(this.state.payVal)/100,
            payVal: 0,
            legacyQRloading: false,
            legacyQRcode: true
        });

    }

    async switchToLegacy() {
        this.setState({
            legacyQRloading: true
        });

        const pr = await this.tx.toLegacy();

        this.setState({
            legacyQRloading: false,
            legacyQRcode: true,
            showQR: pr
        });
    }

    async cancelTransaction() {
        this.setState({
            cancellingTransaction: true,
            showQR: null
        });

        await this.tx.cancel();

        this.setState({
            cancellingTransaction: false,
            showPos: true,
            step: 0
        });
    }

    render() {

        const rows = [];

        for(let i=0;i<3;i++) {
            const cols = [];
            for(let e=0;e<3;e++) {
                cols.push((
                    <Col xs={4} className={"p-1"}>
                        <Button onClick={() => {
                            this.clickNumber((e+1)+(3*i))
                        }} variant="dark" size={"lg"} style={{
                            width: "100%",
                            height: "100%"
                        }}>{(e+1)+(3*i)}</Button>
                    </Col>
                ));
            }
            rows.push((
                <Row style={{
                    "--bs-gutter-x": "0",
                    height: "20%"
                }}>
                    {cols}
                </Row>
            ))
        }

        rows.push((
            <Row style={{
                "--bs-gutter-x": "0",
                height: "20%"
            }}>
                <Col xs={4} className={"p-1"}>
                    <Button onClick={async () => {
                        await this.clickNumber(0);
                        await this.clickNumber(0);
                    }} variant="dark" size={"lg"} style={{
                        width: "100%",
                        height: "100%"
                    }}>00</Button>
                </Col>
                <Col xs={4} className={"p-1"}>
                    <Button onClick={() => {
                        this.clickNumber(0);
                    }} variant="dark" size={"lg"} style={{
                        width: "100%",
                        height: "100%"
                    }}>0</Button>
                </Col>
                <Col xs={4} className={"p-1"}>
                    <Button onClick={() => {
                        this.setState({
                            payVal: 0
                        });
                    }} variant="danger" size={"lg"} style={{
                        width: "100%",
                        height: "100%"
                    }}>C</Button>
                </Col>
            </Row>
        ));

        rows.push((
            <Row style={{
                "--bs-gutter-x": "0",
                height: "20%"
            }}>
                {this.state.step===1 ? (
                    <Col xs={4} className={"px-1 py-3"}>
                        <Button className="rounded-pill" variant={"danger"} onClick={async () => {
                            this.setState({
                                step: 0,
                                payVal: 0,
                                payPos: null
                            });
                        }} style={{
                            width: "100%",
                            height: "100%"
                        }} size={"lg"}>Back</Button>
                    </Col>
                ) : ""}
                <Col xs={this.state.step===0 ? 12 : 8} className={"px-1 py-3"}>
                    <Button className="rounded-pill" variant={"success"} disabled={this.state.step===1 && parseInt(this.state.baseVal)>parseInt(this.state.payVal)} onClick={async () => {
                        if(this.state.step===1) {
                            this.startPayment();
                        } else {
                            if(this.state.payVal!=null && parseInt(this.state.payVal)>0) this.setState({
                                step: 1,
                                baseVal: this.state.payVal,
                                payPos: null,
                                txRef: this.transactionRef.value,
                                startPaymentErr: null
                            });
                        }
                    }} style={{
                        width: "100%",
                        height: "100%"
                    }} size={"lg"}>Charge<b>{this.state.step===1 ? " "+(this.state.payVal==null ? (parseInt(this.state.baseVal)/100).toFixed(2) : ((parseInt(this.state.payVal))/100).toFixed(2)) : ""}</b></Button>
                </Col>
            </Row>
        ));


        return (
            <div className="App">
                <header className="App-header">
                    <div style={{width: "100%", maxWidth: 600}}>
                        {this.state.loading ? (
                            <div>
                                <Spinner animation="border" />
                                <h3>Loading...</h3>
                            </div>
                        ) : ""}
                        {this.state.showError ? (
                            <Alert variant="danger">
                                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                <p>
                                    {this.state.showError}
                                </p>
                            </Alert>
                        ) : ""}
                        {this.state.showNotRegistered ? (
                            <Alert variant="warning">
                                <Alert.Heading>Device not yet assigned to any account!</Alert.Heading>
                                <p>
                                    <ol>
                                        <li>Go to the dashboard and <b>Add device</b> in <b>Devices</b> tab</li>
                                        <li><b>Scan</b> the QR code.</li>
                                    </ol>
                                    {this.state.scanning ? (
                                        <QrReader
                                            onResult={(result, error) => {
                                                if (!!error) {
                                                    //console.info(error);
                                                    return;
                                                }
                                                if(result) {
                                                    console.log(result);
                                                    const resultText = result.text;
                                                    try {
                                                        const url = new URL(resultText);
                                                        console.log(url);
                                                        if(url.hostname==="pos.gethopa.com" || url.hostname==="hopapostest.z6.web.core.windows.net") {
                                                            const queryDict = {};
                                                            url.search.substr(1).split("&").forEach(function(item) {
                                                                queryDict[item.split("=")[0]] = item.split("=")[1]
                                                            }.bind(this));
                                                            if(queryDict.l!=null || queryDict.r!=null) {
                                                                this.queryDict = queryDict;
                                                                this.init();
                                                            }
                                                        }
                                                    } catch (e) {

                                                    }
                                                }
                                            }}
                                            constraints={{
                                                facingMode: "environment"
                                            }}
                                            style={{ width: '100%' }}
                                            showViewFinder={true}
                                        />
                                    ) : ""}
                                    <Button variant="success" onClick={() => {
                                        this.setState({
                                            scanning: !this.state.scanning
                                        });
                                    }}>{this.state.scanning ? "Close camera" : "Open camera"}</Button>
                                </p>
                            </Alert>
                        ) : ""}
                        {this.state.showPos ? (
                            <div style={{
                                width: "100%",
                                height: "100vh",
                                minHeight: this.state.startPaymentErr ? "650px" : "600px"
                            }} className={"px-4"}>

                                <div style={{
                                    height: "40%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    {this.state.step===0 ? (
                                        <div>

                                            {this.state.startPaymentErr ? (
                                                <Alert variant="danger">
                                                    <Alert.Heading>{this.state.startPaymentErr}</Alert.Heading>
                                                </Alert>
                                            ) : ""}

                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <span className={"me-4"} style={{
                                                    fontSize: "70px",
                                                    display: "inline"
                                                }}>{(this.state.payVal==null ? "0.00" : (parseInt(this.state.payVal)/100).toFixed(2))}</span>

                                                <DropdownButton
                                                    className={"pt-2"}
                                                    size={"lg"}
                                                    variant="outline-secondary"
                                                    title={this.state.baseCurrency}
                                                    id="input-group-dropdown-4"
                                                    align="center"
                                                    style={{
                                                        display: "inline"
                                                    }}
                                                >
                                                    <Dropdown.Item href="#" onClick={() => {
                                                        this.setState({
                                                            baseCurrency: "EUR"
                                                        })
                                                    }}>EUR</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => {
                                                        this.setState({
                                                            baseCurrency: "USD"
                                                        })
                                                    }}>USD</Dropdown.Item>
                                                </DropdownButton>
                                            </div>

                                            <InputGroup className={"p-1"} variant={"secondary"}>
                                                <Form.Control
                                                    variant={"secondary"}
                                                    size="lg"
                                                    type="text"
                                                    ref={(ref) => {
                                                        this.transactionRef = ref;
                                                    }}
                                                    placeholder={"Transaction ID"}
                                                />
                                            </InputGroup>
                                        </div>
                                    ) : (
                                        <div>

                                            {this.state.startPaymentErr ? (
                                                <Alert variant="danger">
                                                    <Alert.Heading>{this.state.startPaymentErr}</Alert.Heading>
                                                </Alert>
                                            ) : ""}

                                            <h1 className="mb-4">Add a tip?</h1>
                                            <h4>Full amount (with tip)</h4>

                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <span className={"me-4"} style={{
                                                    fontSize: "70px",
                                                    display: "inline"
                                                }}>{(this.state.payVal==null ? "0.00" : (parseInt(this.state.payVal)/100).toFixed(2))}</span>

                                                <button
                                                    className={"btn btn-outline-secondary btn-lg mt-2"}
                                                    id="input-group-dropdown-4"
                                                    align="center"
                                                    disabled={true}
                                                    style={{
                                                        display: "inline"
                                                    }}
                                                >
                                                    {this.state.baseCurrency}
                                                </button>
                                            </div>
                                            {this.state.payVal==null ? "" : (
                                                <div>
                                                    {parseInt(this.state.payVal) > parseInt(this.state.baseVal) ? (
                                                        <span><b>{(parseInt(this.state.baseVal)/100).toFixed(2)}</b> {this.state.baseCurrency} + <b>{((parseInt(this.state.payVal)-parseInt(this.state.baseVal))/100).toFixed(2)}</b> {this.state.baseCurrency} tip</span>

                                                    ) : parseInt(this.state.payVal) < parseInt(this.state.baseVal) ? (
                                                        <span>Must be larger than <b>{(parseInt(this.state.baseVal)/100).toFixed(2)}</b> {this.state.baseCurrency}</span>
                                                    ) : "No tip"}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div style={{
                                    height: "60%"
                                }}>
                                    {rows}
                                </div>
                            </div>
                        ) : ""}
                        {this.state.initiatingPayment ? (
                            <div>
                                <Spinner animation="border" />
                                <h3>Initiating payment...</h3>
                            </div>
                        ) : ""}
                        {this.state.showQR ? (
                            <div style={{
                                width: "100%",
                                height: "100vh",
                                minHeight: "750px"
                            }}>

                                <div style={{
                                    height: "80%"
                                }}>

                                    <h4 className="pt-3">Payment of </h4>
                                    <h1 style={{
                                        fontSize: 70
                                    }}>{this.state.txValue.toFixed(2)} {this.state.baseCurrency}</h1>

                                    <QRCode
                                        size={Math.min(this.state.width-100, this.state.height*0.6, 600)}
                                        value={this.state.showQR}
                                        includeMargin={false}
                                        id={"qrCodeCanvas"}
                                    />

                                </div>

                                <div className={"px-4"} style={{
                                    height: "20%"
                                }}>
                                    <Col xs={12} style={{
                                        height: "40%"
                                    }} className={"px-1 py-3"}>
                                        {!this.state.legacyQRcode ? (
                                            <div>
                                                <span style={{
                                                        fontSize: "20px"
                                                    }}>Doesn't work?</span> <Button variant="primary" className={"rounded-pill mb-2"} disabled={this.state.legacyQRloading} onClick={() => {
                                                this.switchToLegacy();
                                            }}>{this.state.legacyQRloading ? (
                                                <>
                                                    Loading... <Spinner animation="border" size="sm"/>
                                                </>
                                            ) : "Switch to legacy QR code"}</Button>
                                            </div>
                                        ) : ""}
                                    </Col>
                                    <Col xs={12} style={{
                                        height: "60%"
                                    }} className={"px-1 py-3"}>
                                        <Button variant="danger" size={"lg"} className={"rounded-pill"} style={{
                                            width: "100%",
                                            height: "100%"
                                        }} onClick={() => {
                                            this.cancelTransaction();
                                        }}>Cancel transaction</Button>
                                    </Col>
                                </div>
                            </div>
                        ) : ""}
                        {this.state.cancellingTransaction ? (
                            <div>
                                <Spinner animation="border" />
                                <h3>Cancelling payment...</h3>
                            </div>
                        ) : ""}
                        {this.state.paymentSuccess!=null ? (
                            <div style={{
                                width: "100%",
                                height: "100vh",
                                minHeight: "750px"
                            }} className={"px-4"}>
                                {this.state.paymentSuccess ? (
                                    <div style={{
                                        height: "88%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <h1 style={{
                                            fontSize: 60
                                        }}>Payment successful</h1>

                                        <img src={"/checked.png"} style={{
                                            maxWidth: "100%"
                                        }} className={"p-5"}/>

                                        <h1>{this.state.txValue.toFixed(2)} {this.state.baseCurrency}</h1>
                                        {this.state.txRef!=null && this.state.txRef!=="" ? (
                                            <h4>
                                                #{this.state.txRef}
                                            </h4>
                                        ) : ""}
                                    </div>
                                ) : (
                                    <div style={{
                                        height: "88%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <h1 style={{
                                            fontSize: 60
                                        }}>Payment failed</h1>

                                        <img src={"/cancel.png"} style={{
                                            maxWidth: "100%"
                                        }} className={"p-5"}/>
                                    </div>
                                )}
                                <Col xs={12} style={{
                                    height: "12%"
                                }} className={"px-1 py-3"}>
                                    <Button variant="success" size={"lg"} className={"rounded-pill"} style={{
                                        width: "100%",
                                        height: "100%"
                                    }} onClick={() => {
                                        this.setState({
                                            showPos: true,
                                            paymentSuccess: null
                                        });
                                    }}>OK</Button>
                                </Col>
                            </div>
                        ) : ""}
                    </div>
                </header>
            </div>
        );
    }
}

export default App;
