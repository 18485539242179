const request = require("request-promise-native");
const crypto = require("crypto");
const secp256k1 = require('secp256k1');
const { v4: uuidv4 } = require('uuid');

const MAX_TIMESTAMP_SKEW = 15000;
const serverPubkey = Buffer.from("02e4067547ad8d63ea5e94a676dbb21294348b241d1a1148f57e9d02c7fa5a0356", "hex");

const mqtt = require('mqtt');

class DeviceHandler {

    constructor(regId, privkeyHex, baseUrl, linkId, rebind) {
        this.regId = regId;
        this.privkeyHex = privkeyHex;
        this.privkey = Buffer.from(privkeyHex, "hex");
        this.pubKey = secp256k1.publicKeyCreate(this.privkey);
        this.pubkeyHex = Buffer.from(this.pubKey).toString("hex");
        this.baseUrl = baseUrl;
        this.linkId = linkId;
        this.rebind = rebind;
    }

    async init() {
        //Send auth request
        const authObj = {
            regId: this.regId,
            pubkeyHex: this.pubkeyHex,
        };
        if(this.linkId!=null) {
            authObj.link = this.linkId;
        }
        if(this.rebind!=null) {
            const arr = this.rebind.split("_");
            authObj.rebindKey = arr[0];
            authObj.regId = Buffer.from(arr[1], "hex").toString("base64");
        }
        this.signObject(authObj);

        const options = {
            method: 'POST',
            uri: this.baseUrl+"/DeviceAuth",
            json: true,
            body: authObj,
            resolveWithFullResponse: true,
            simple: false
        };

        const response = await request(options);

        console.log(response.body);

        if(response.statusCode!==200 || response.body==null) {
            console.log("Auth failed");
            console.log(response.body);
            return {
                error: response.body
            };
        }

        if(response.body.code===10000) {
            this.baseCurrency = response.body.data.baseCurrency;
            this.supportedCurrencies = response.body.supportedCurrencies;
            if(this.rebind!=null) {
                this.regId = authObj.regId;
                return {
                    success: true,
                    regId: this.regId
                };
            }
            return {
                success: true
            };
        }

        return {
            error: response.body
        };
    }

    async startTransaction(currency, amount, cryptocurrency, resultCbk, transactionLink, tip) {
        const obj = {
            regId: this.regId,
            currency,
            amount,
            cryptocurrency
        };
        if(transactionLink!=null) obj.transactionLink = transactionLink;
        if(tip!=null) obj.tip = tip;
        this.signObject(obj);

        const options = {
            method: 'POST',
            uri: this.baseUrl+"/DevicePaymentInit",
            json: true,
            body: obj,
            resolveWithFullResponse: true,
            simple: false
        };

        const response = await request(options);

        if(response.statusCode!==200 || response.body==null) {
            console.log("Start TX failed");
            console.log(response.body);
            return response.body;
        }

        console.log(response.body);

        if(!this.verifyObject(response.body.data)) {
            return {
                msg: "Invalid signature"
            };
        }

        console.log("Opening connection!");
        const client = mqtt.connect("wss://"+response.body.data.mqttBroker+"/$iothub/websocket?iothub-no-client-cert=true", {
            clientId: response.body.data.mqttClientId,
            username: response.body.data.mqttUsername,
            password: response.body.data.mqttPassword,
            protocolVersion: 4,
            protocolId: "MQTT",
            reconnectPeriod: 0
        });

        //console.log("Creating SAS");
        //const client = Protocol.clientFromConnectionString("HostName=thunderpay.azure-devices.net;DeviceId=54686520717569636b206272;SharedAccessKey=2lzgS5MwMTwp1ep0dCgtYrG54z9yTzXqB9zoXE/0Epg=");
        //const client = Client.fromSharedAccessSignature(sas, Protocol.MqttWs);

        await new Promise((resolve, reject) => {
            client.on("connect", () => {
                resolve();
            });
            client.on("error", (err) => {
                reject(err);
            });
        });

        console.log("Connection open!");

        client.on("message", (topic, message, packet) => {
            message = message.toString();
            console.log(topic, message);
            const arr = topic.split("/");
            const last = arr[arr.length-1];
            const arr2 = last.split("=");
            const rid = arr2[1];
            client.publish("$iothub/methods/res/10000/?$rid="+rid, "1", (err) => {
                if(err) {
                    console.log(err);
                    resultCbk(false);
                    return;
                }

                client.end();

                if(message==="\"1\"") {
                    resultCbk(true);
                } else {
                    resultCbk(false);
                }
            });
        });

        await new Promise((resolve, reject) => {
            client.subscribe("$iothub/methods/POST/result/#", null, (err) => {
                if(err) {
                    reject(err);
                    return;
                }
                resolve();
            });
        });

        /*
        client.onDeviceMethod("result", async (req, res) => {
            console.log(req);
            if(req.payload==="1") {
                resultCbk(true);
            } else {
                resultCbk(false);
            }
            await res.send(10000, "");
            await client.close();
        });

        console.log("Opening connection!");
        await client.open();
        console.log("Connection open!");
        */

        return {
            cancel: async () => {

                const obj = {
                    regId: this.regId
                };
                this.signObject(obj);

                const options = {
                    method: 'POST',
                    uri: this.baseUrl+"/DevicePaymentCancel",
                    json: true,
                    body: obj,
                    resolveWithFullResponse: true,
                    simple: false
                };

                const response = await request(options);

                if(response.statusCode!==200 || response.body==null) {
                    console.log("Cancel TX failed");
                    console.log(response.body);
                    return false;
                }

                await new Promise(resolve => {
                    client.end(true, null, () => {
                        resolve();
                    });
                });
                return true;
            },
            toLegacy: async () => {

                const obj = {
                    regId: this.regId
                };
                this.signObject(obj);

                const options = {
                    method: 'POST',
                    uri: this.baseUrl+"/DevicePaymentLegacyLN",
                    json: true,
                    body: obj,
                    resolveWithFullResponse: true,
                    simple: false
                };

                const response = await request(options);

                if(response.statusCode!==200 || response.body==null) {
                    console.log("Legacy TX conversion failed");
                    console.log(response.body);
                    return null;
                }

                return response.body.data.pr;

            },
            qr: response.body.data.qr
        };
    }

    signObject(responseObj) {
        responseObj.timestamp = Date.now();
        responseObj.uuid = uuidv4();

        let keys = [];
        for(let k in responseObj) {
            keys.push(k);
        }
        keys.sort();
        let signStrings = [];
        for(let k of keys) {
            signStrings.push(k+"="+responseObj[k]);
        }
        const signString = signStrings.join("&");

        const toSignBuff = crypto.createHash("sha256")
            .update(signString)
            .digest();

        const sig = secp256k1.ecdsaSign(toSignBuff, this.privkey);

        responseObj.signature = Buffer.from(sig.signature).toString("hex");
    }

    verifyObject(obj) {
        if(Math.abs(obj.timestamp-Date.now())>MAX_TIMESTAMP_SKEW) {
            return false;
        }

        const bodyCpy = {...obj};
        delete bodyCpy.signature;

        let keys = [];
        for(let k in bodyCpy) {
            keys.push(k);
        }
        keys.sort();
        let signStrings = [];
        for(let k of keys) {
            signStrings.push(k+"="+bodyCpy[k]);
        }
        const signString = signStrings.join("&");

        const checkSigBuff = crypto.createHash("sha256")
            .update(signString)
            .digest();

        if(!secp256k1.ecdsaVerify(secp256k1.signatureNormalize(Buffer.from(obj.signature, "hex")), checkSigBuff, serverPubkey)) {
            return false;
        }

        return true;
    }

    static generateKeyPair() {
        // generate privKey
        let privKey
        do {
            privKey = crypto.randomBytes(32)
        } while (!secp256k1.privateKeyVerify(privKey))

        // get the public key in a compressed format
        const pubKey = secp256k1.publicKeyCreate(privKey)

        return {
            privkeyHex: privKey.toString("hex"),
            pubkeyHex: Buffer.from(pubKey).toString("hex")
        }
    }

    static generateRegId() {
        return crypto.randomBytes(12).toString("base64");
    }

}

export default DeviceHandler;